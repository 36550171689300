import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { keyframes, styled } from '../themes'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
const SpinnerIcon = styled(FontAwesomeIcon)`
  animation: ${spin} 0.5s cubic-bezier(1, 0.25, 0, 0.75) infinite;
  color: #dddddd;
`
interface ISpinnerProps {
  show?: boolean
  imgSrc?: string
}

/**
 * @deprecated As an alternative, use LoadingModal or LogoSpinner instead.
 */
const Spinner = ({ imgSrc, show, ...props }: ISpinnerProps) =>
  show === undefined || show === true ? (
    <p style={{ textAlign: 'center' }} {...props} data-cy='spinner'>
      {imgSrc ? (
        <img src={imgSrc} alt='processing...' />
      ) : (
        <SpinnerIcon size='3x' icon={faCircleNotch} />
      )}
    </p>
  ) : null

export { Spinner }
